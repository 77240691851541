document.addEventListener("DOMContentLoaded", function () {
  const readMoreBtn = document.querySelector(".read-more-btn");
  if (readMoreBtn) {
    readMoreBtn.addEventListener("click", function () {
      const hiddenContent = document.querySelector(".hidden-content");
      hiddenContent.classList.toggle("show");
      this.classList.toggle("active");
      this.innerHTML =
        this.innerHTML === "Read More" ? "Read Less" : "Read More";
    });
  }
});

// Tab functionality
document.addEventListener('DOMContentLoaded', function () {
    const tabLinks = document.querySelectorAll('.tabs-nav-slide a');

    tabLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            e.preventDefault();

            // Remove active classes
            document.querySelectorAll('.tabs-nav-slide').forEach(tab => {
                tab.classList.remove('active');
            });
            document.querySelectorAll('.tab-content').forEach(content => {
                content.classList.remove('active');
            });

            // Add active class to clicked tab
            link.parentElement.classList.add('active');

            // Show corresponding content
            const contentId = link.getAttribute('href');
            document.querySelector(contentId).classList.add('active');
        });
    });
}); 
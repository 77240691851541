import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", function () {
  //hero slider
  if (document.querySelector(".hero-slider")) {
    var heroSplide = new Splide(".hero-slider", {
      type: "loop",
      perPage: 1,
      arrows: false,
      autoplay: true,
      cover: true,
      height: "523px",
      lazyLoad: true,
      breakpoints: {
        991: {
          height: "336px",
        },
      },
    });

    //secondary slider for hero slider - with descriptions
    var HeroDescSplider = new Splide(".hero-desc-slider", {
      type: "fade",
      easing: "none",
      arrows: false,
      pagination: false,
      drag: false,
    });

    //syncing these two
    HeroDescSplider.sync(heroSplide);

    //mounting both
    HeroDescSplider.mount();
    heroSplide.mount();

    let heroDescSlides = document.querySelectorAll(
      ".hero-desc-slider .splide__slide"
    );
    heroDescSlides.forEach((slide) => {
      slide.classList.add("container");
    });
  }

  //slider in "who we are" section
  if (document.querySelector(".who-we-are__slider")) {
    var whoWeAreSplider = new Splide(".who-we-are__slider", {
      type: "loop",
      pagination: false,
      fixedWidth: "475px",
      arrows: true,
      autoplay: true,
      cover: true,
      height: "305px",
      lazyLoad: true,
      perMove: 1,
      padding: "10%",
      preloadPages: 2,
      focus: "center",
      arrowPath:
        "M2.91122 0.276367L0 2.5265L18.329 26.2424L0 49.9587L2.89506 52.6314L23 26.2692L2.91122 0.276367Z",
      breakpoints: {
        991: {
          height: "243px",
          fixedWidth: 0,
          perPage: 1,
          padding: "0",
          preloadPages: 1,
        },
      },
    });

    whoWeAreSplider.mount();
  }

  if (document.querySelector(".testimonials__slider")) {
    const testimonialSlider = new Splide(".testimonials__slider", {
      type: "loop",
      perPage: 1,
      perMove: 1,
      arrows: true,
      pagination: true,
      interval: 5000,
      gap: 20,
      pauseOnHover: false,
      pauseOnFocus: false,
      speed: 800,
      easing: "ease",
      drag: true,
    });

    // Function to update track height
    const updateTrackHeight = () => {
      if (window.innerWidth < 992) {
        // Only on mobile
        const currentIndex = testimonialSlider.index;
        const activeSlide =
          testimonialSlider.Components.Elements.slides[currentIndex];
        const testimonial = activeSlide?.querySelector(
          ".testimonials__slider .testimonial"
        );
        const track = document.querySelector(
          ".testimonials__slider  .splide__track"
        );

        if (testimonial && track) {
          const height = testimonial.offsetHeight;
          track.style.height = `${height}px`;
          track.style.transition = "height 0.3s ease";
          console.log(
            "updating track height to:",
            height,
            "for slide:",
            currentIndex
          );
        }
      } else {
        // Reset height on desktop
        const track = document.querySelector(
          ".testimonials__slider  .splide__track"
        );
        if (track) {
          track.style.height = "";
          track.style.transition = "";
        }
      }
    };

    testimonialSlider.mount();

    setTimeout(() => {
      const autoplayButton = document.createElement("li");
      autoplayButton.className = "autoplay-button is-playing";
      autoplayButton.setAttribute("aria-label", "Toggle autoplay");
      testimonialSlider.root
        .querySelector(".splide__pagination")
        .appendChild(autoplayButton);

      autoplayButton.addEventListener("click", () => {
        if (autoplayButton.classList.contains("is-playing")) {
          testimonialSlider.Components.Autoplay.pause();
          autoplayButton.classList.remove("is-playing");
        } else {
          testimonialSlider.Components.Autoplay.play();
          autoplayButton.classList.add("is-playing");
        }
      });

      testimonialSlider.Components.Autoplay.play();
    }, 0);
  }
  if (document.querySelector(".events-slider")) {
    const eventsSlider = document.querySelector(".events-slider");
    const eventsSplide = new Splide(eventsSlider, {
      type: "slide",
      perPage: 1,
      gap: 24,
      pagination: true,
      arrows: false,
      breakpoints: {
        768: {
          perPage: 1,
          gap: 16,
        },
      },
    }).mount();
    const prevButton = document.querySelector(".slider-arrow.prev");
    const nextButton = document.querySelector(".slider-arrow.next");

    if (prevButton && nextButton) {
      prevButton.addEventListener("click", () => {
        eventsSplide.go("<");
      });

      nextButton.addEventListener("click", () => {
        eventsSplide.go(">");
      });
    }
  }
});

var scrolledNavbar = function (event) {
  let navbar = document.getElementById("navbar_top");
  let navbar_height;
  if (window.innerWidth <= 991) {
    navbar_height = 48;
  } else {
    navbar_height = 96;
  }
  if (window.scrollY >= navbar_height) {
    navbar.classList.add("scrolled");
  } else {
    navbar.classList.remove("scrolled");
  }
};

window.addEventListener("scroll", scrolledNavbar, false);
window.addEventListener("resize", scrolledNavbar, false);
document.addEventListener("DOMContentLoaded", scrolledNavbar, false);

document.addEventListener("DOMContentLoaded", function () {
  const navLinks = document.querySelectorAll('.navbar-collapse a[href^="#"]');

  navLinks.forEach((link) => {
    link.addEventListener("click", function (e) {
      // Close the mobile nav
      document.querySelector(".navbar-collapse").classList.remove("show");
      // nav.classList.remove("active");
    });
  });
});
